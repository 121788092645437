<template>
  <div>
    <div class="fill-height purple-gradient">
      <Navbar />
      <v-container class="px-5 px-sm-9">
        <SearchBar />
      </v-container>
    </div>
    <div
      class="body-container"
      :style="[
        $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
          ? { 'padding-bottom': '30px' }
          : { 'padding-bottom': '70px' },
      ]"
    >
      <v-container class="px-10 px-md-auto">
        <div>
          <div class="mx-0 mb-1 text-h2 text-md-h1 font-weight-bold">
            Paquetes Zaturna
          </div>
          <div
            class="flex-row text-subtitle-1 text-sm-h4 font-weight-regular mb-4 ml-md-1"
            :class="loadingFirstPackages && 'mb-16 mb-sm-12 pb-2 pb-sm-0'"
          >
            <div v-if="!loadingFirstPackages">
              <span class="d-flex d-sm-inline-flex">
                {{
                  packagesQuantity !== 1
                    ? packagesQuantity + " paquetes " + "publicados"
                    : packagesQuantity + " paquete " + "publicado"
                }}
              </span>
              <span class="d-flex d-sm-inline-flex mx-sm-8">
                <v-icon class="mr-2 secondary--text" small>fa-map-pin</v-icon>
                {{
                  citiesQuantity != 1
                    ? citiesQuantity + " ciudades"
                    : citiesQuantity + " ciudad"
                }}
              </span>
            </div>
          </div>
        </div>
        <v-divider class="mx-n2 secondary" />
        <!-- <CardsFilters
          :filters="filters"
          :filters-used="filtersUsed"
          @removeFilters="removeFilters"
        /> -->
        <div
          v-if="
            !loadingFirstPackages &&
            !loadingMorePackages &&
            packagesQuantity === 0
          "
          class="my-16 text-center descent--text text--darken-2 text-h4 font-weight-regular"
        >
          No se han encontrado paquetes
        </div>
      </v-container>
      <InfiniteListCards
        v-if="packagesQuantity !== 0"
        :cards="packages"
        :no-more-cards="noMorePackages"
        :loading-first-cards="loadingFirstPackages"
        :loading-more-cards="loadingMorePackages"
        type="Package"
        @handleMoreCards="fetchMorePackages"
      />
      <v-container
        v-if="loadingFirstPackages || loadingMorePackages"
        class="d-flex flex-column align-center my-10 my-sm-12"
      >
        <v-progress-circular indeterminate color="secondary" :size="100" />
      </v-container>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/LandingPage/Shared/Navbar.vue";
import SearchBar from "@/components/Shared/SearchBar.vue";
// import CardsFilters from "@/components/Shared/CardsFilters.vue";
import InfiniteListCards from "@/components/LandingPage/Shared/InfiniteListCards.vue";
import Footer from "@/components/LandingPage/Shared/Footer.vue";
import {
  GET_PACKAGES_WITHOUT_CURSOR,
  GET_PACKAGES_WITH_CURSOR,
} from "@/graphql/queries";
import { useQuery } from "@/graphql/index";
// import { getDistinctCitiesByState } from "@/Utils/locations.js";

export default {
  name: "Packages",
  components: {
    Navbar,
    SearchBar,
    // CardsFilters,
    InfiniteListCards,
    Footer,
  },
  data: () => ({
    // filters: [
    //   {
    //     type: "search",
    //     name: "Nombre",
    //     model: "",
    //   },
    //   {
    //     type: "options",
    //     name: "Tipo de servicio",
    //     model: "",
    //     options: [],
    //     noDataText: "Tipo de servicio no encontrado",
    //     autofocus: false,
    //   },
    //   {
    //     type: "location",
    //     state: {
    //       name: "Estado",
    //       model: "",
    //       options: [],
    //     },
    //     city: {
    //       name: "Ciudad",
    //       model: "",
    //       options: getDistinctCitiesByState,
    //       distinctOptions: [],
    //     },
    //   },
    // ],
    packages: [],
    packagesQuantity: 0,
    citiesQuantity: 0,
    noMorePackages: false,
    loadingFirstPackages: true,
    loadingMorePackages: false,
  }),
  computed: {
    packagesPerPageQuantity() {
      return this.$vuetify.breakpoint.xs
        ? 6
        : this.$vuetify.breakpoint.sm
        ? 10
        : 16;
    },
    // filtersValues() {
    //   return [
    //     this.filters[0].model,
    //     this.filters[1].model,
    //     this.filters[2].state.model,
    //     this.filters[2].city.model,
    //     this.filters[3].model,
    //   ];
    // },
    // filtersUsed() {
    //   if (
    //     this.filters[0].model ||
    //     this.filters[1].model ||
    //     this.filters[2].state.model ||
    //     this.filters[2].city.model ||
    //     this.filters[3].model
    //   ) {
    //     return true;
    //   }
    //   return false;
    // },
  },
  // watch: {
  //   "$route.query.nombre": {
  //     async handler(val) {
  //       if (!val) return;
  //       this.filters[0].model = val;
  //     },
  //     immediate: true,
  //   },
  //   "$route.query.estado": {
  //     async handler(val) {
  //       if (!val) return;
  //       this.filters[2].state.model = val;
  //     },
  //     immediate: true,
  //   },
  //   "$route.query.ciudad": {
  //     async handler(val) {
  //       if (!val) return;
  //       this.filters[2].city.model = val;
  //     },
  //     immediate: true,
  //   },
  //   filtersValues: function () {
  //     this.fetchFirstPackages();
  //   },
  // },
  created() {
    this.fetchFirstPackages();
  },
  methods: {
    // loadFilters(props) {
    //   if (this.filters[0].model !== "") {
    //     props = { ...props, name: this.filters[0].model };
    //   }
    //   if (this.filters[1].model !== "") {
    //     props = { ...props, category: this.filters[1].model };
    //   }
    //   if (this.filters[2].state.model !== "") {
    //     props = { ...props, state: this.filters[2].state.model };
    //     if (this.filters[2].city.model !== "") {
    //       props = { ...props, city: this.filters[2].city.model };
    //     }
    //   }
    //   return props;
    // },

    async fetchFirstPackages() {
      this.loadingFirstPackages = true;
      this.noMorePackages = false;
      this.packages = [];
      let props = { perPage: this.packagesPerPageQuantity };

      const { data, errors } = await useQuery(
        GET_PACKAGES_WITHOUT_CURSOR,
        props
      );
      if (data) {
        this.packages = this.formatPackages(data.packages.packages);
        this.cursor = data.packages.cursor;
        this.packagesQuantity = data.countPackages.packagesCount;
        this.citiesQuantity = data.countPackages.packagesCitiesCount;
        if (this.packagesQuantity <= this.packagesPerPageQuantity) {
          this.noMorePackages = true;
        }
      } else if (errors) {
        console.log(errors);
      }
      this.loadingFirstPackages = false;
    },
    async fetchMorePackages() {
      if (
        !this.loadingFirstPackages &&
        !this.loadingMorePackages &&
        !this.noMorePackages
      ) {
        this.loadingMorePackages = true;
        let props = {
          perPage: this.packagesPerPageQuantity,
          cursor: this.cursor,
        };
        const { data, errors } = await useQuery(
          GET_PACKAGES_WITH_CURSOR,
          props
        );
        if (data) {
          const newPackagesToAppend = this.formatPackages(
            data.packages.packages
          );
          this.packages = [...this.packages, ...newPackagesToAppend];
          this.cursor = data.packages.cursor;
          this.packagesQuantity = data.countPackages.packagesCount;
          this.citiesQuantity = data.countPackages.packagesCitiesCount;
          if (this.packagesQuantity === this.packages.length) {
            this.noMorePackages = true;
          }
        } else if (errors) {
          console.log(errors);
        }
        this.loadingMorePackages = false;
      }
    },
    formatPackages(packages) {
      const data = packages.map((pkg) => {
        const info = {
          ...pkg.package,
          providerName: pkg.provider.name,
        };
        return info;
      });
      return data;
    },
    // removeFilters() {
    //   this.$router.push({ query: null }).catch(() => {
    //     this.filters.forEach((filter) => {
    //       if (filter.type === "location") {
    //         filter.state.model = "";
    //         filter.city.model = "";
    //       } else if (filter.type === "rating") {
    //         filter.model = 0;
    //       } else {
    //         filter.model = "";
    //       }
    //     });
    //   });
    // },
  },
};
</script>

<style scoped lang="scss">
.purple-gradient {
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(98, 37, 130, 1) 100%
  );
  background-size: cover !important;
}
.slide-y-transition-leave-to {
  transform: none;
}
</style>
